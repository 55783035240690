import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../containers/SaasModern/Documentation';
import categories_import from '../../../../../../common/src/assets/image/2019/categories_import.png';
import categories_defined from '../../../../../../common/src/assets/image/2019/categories_defined.png';
import categories_legend_in_template from '../../../../../../common/src/assets/image/2019/categories_legend_in_template.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        Categories can be imported or created in the PrintableCal Options. When
        categories are defined, events and tasks will be color-coded in the
        output based on the associated category colors, rather than the calendar
        data source colors. Depending on the calendar data source,{' '}
        <Link to="/Documentation/Options/Calendar-Data-Source-Options">
          this can also be overridden
        </Link>
        .{' '}
      </p>
      <p>
        To create or import categories, open the Options window by clicking the{' '}
        <strong>Options </strong>button on the PrintableCal tab. Categories are
        specified on the <strong>Category Colors</strong> tab of the Options
        window. If you use Exchange, Outlook, or VueMinder, you can import
        categories from those sources by clicking the <strong>Import </strong>
        button.
      </p>
      <p>
        <Image alt="" src={categories_import} style={{ width: 891 }} />
      </p>
      <p>
        You can also define categories by clicking the{' '}
        <strong>New Category</strong> button. Each category can be configured
        with colors (background, text, and border), font (name, size, style,
        alignment), and border thickness.
      </p>
      <p>
        <Image alt="" src={categories_defined} style={{ width: 891 }} />
      </p>
      <p>
        When generating a calendar, any items associated with a defined category
        will use that category's appearance settings. Templates that include a{' '}
        <Link to="/Documentation/Insert-Legend">legend </Link>will also show the
        category as part of the legend. Not all the defined categories will be
        in the legend - only categories associated with items that are actually
        shown in the output.
      </p>
      <p>
        <Image alt="" src={categories_legend_in_template} />
      </p>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Options/Category-Colors"
      commentsId="commentsplus_post_1203_489"
      title="Category Colors"
      description="Categories can be imported or created in the PrintableCal Options. When categories are defined, events and tasks will be color-coded in the output based on the associated category colors. "
      keywords="printable calendar, color-code category, category legend"
      content={content}
    />
  );
};

export default Documentation;
